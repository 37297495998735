















































































































































import { Component, Vue } from "vue-property-decorator";
import apexchart from "vue-apexcharts";
import { EChartTypes } from "@/misc/Enums";
@Component({
	components: {
		apexchart,
	},
	props: {
		charttype: {
			type: Number,
			default: null,
		},
		titleText: {
			type: String,
			default: null,
		},
		titleValue: {
			type: Number,
			default: null,
		},
		labels: {
			type: Array,
			default: null,
		},
		dataset: {
			type: Array,
			default: null,
		},
		colors: {
			type: Array,
			default: null,
		},
		chartname: {
			type: String,
			default: null,
		},
	},
})
export default class ChartPreview extends Vue {
	private textSeries: any = [];
	private textLabels: any = [];
	private donutTotal: number = -1;
	private totalData: number = 0;
	private donutSections: any = [];
	private dataSeries = [
		{
			data: [],
		},
	];
	private series = [];

	private checkType(type: number): boolean {
		return this.getType() === type;
	}
	private getLabels() {
		return this.$props.labels;
	}
	private getDataset() {
		return this.$props.dataset;
	}
	private getName() {
		return this.$props.chartname;
	}
	private getColors() {
		return this.$props.colors;
	}
	private getKeys() {
		return this.$props.keys;
	}
	private getTitleValue() {
		return this.$props.titleValue;
	}
	private getTitleText() {
		return this.$props.titleText;
	}
	private getType() {
		return this.$props.charttype;
	}
	private created() {
		console.log("+");
		this.redraw();
	}

	private redraw() {
		console.log("redraw");
		// let copyPie = Object.assign({}, this.pieOptions);
		// copyPie.labels = this.getLabels();
		let labels = this.getLabels();
		let data = this.getDataset();
		let colors = this.getColors();
		//let copyDonut = [...this.donutSections];
		this.donutSections.length = 0;
		let totalData = 0;
		for (let i = 0; i < data.length; i++) {
			totalData += data[i];
		}
		for (let i = 0; i < labels.length; i++) {
			this.donutSections.push({
				label: labels[i],
				value: (data[i] / totalData) * 100,
				color: colors[i],
			});
		}
		//this.pieOptions = copyPie;
		console.log("sections", this.donutSections);
		let copyBar = Object.assign({}, this.barOptions);
		copyBar.xaxis.categories = labels;
		this.barOptions = copyBar;

		let copyRad = Object.assign({}, this.radialOptions);
		copyRad.labels = labels;
		this.radialOptions = copyRad;

		let copyLine = Object.assign({}, this.lineOptions);
		copyLine.xaxis.categories = labels;
		this.lineOptions = copyLine;

		this.series = this.getDataset();
		this.dataSeries[0].data = this.getDataset();

		this.textSeries = this.getDataset();
		this.textLabels = labels;
	}

	private pieOptions = {
		plotOptions: {
			pie: {
				donut: {
					labels: {
						show: true,
						name: {
							total: "total",
						},
						value: {
							total: 100,
						},
					},
				},
			},
		},
		chart: {
			width: 380,
			type: "donut",
		},
		labels: ["Категория 1", "Категория 2", "Категория 3", "Категория 4"],
		responsive: [
			{
				//breakpoint: 480,
				options: {
					chart: {
						width: 400,
					},
					legend: {
						position: "bottom",
					},
				},
			},
		],
	};
	private barOptions = {
		chart: {
			type: "bar",
			height: 300,
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: true,
			},
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: [
				"South Korea",
				"Canada",
				"United Kingdom",
				"Netherlands",
				"Italy",
				"France",
				"Japan",
				"United States",
				"China",
				"Germany",
			],
		},
	};
	private radialOptions = {
		chart: {
			type: "radialBar",
			offsetY: 0,
			sparkline: {
				enabled: true,
			},
		},
		plotOptions: {
			radialBar: {
				startAngle: -90,
				endAngle: 90,
				track: {
					background: "#e7e7e7",
					strokeWidth: "97%",
					margin: 5, // margin is in pixels
					dropShadow: {
						enabled: true,
						top: 2,
						left: 0,
						color: "#999",
						opacity: 1,
						blur: 2,
					},
				},
				dataLabels: {
					name: {
						show: false,
					},
					value: {
						offsetY: -2,
						fontSize: "22px",
					},
				},
			},
		},
		grid: {
			padding: {
				top: -10,
			},
		},
		fill: {
			type: "gradient",
			gradient: {
				shade: "light",
				shadeIntensity: 0.4,
				inverseColors: false,
				opacityFrom: 1,
				opacityTo: 1,
				stops: [0, 50, 53, 91],
			},
		},
		labels: ["Average Results"],
	};
	private lineOptions = {
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false,
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "straight",
		},
		grid: {
			row: {
				colors: ["#f3f3f3", "transparent"],
				opacity: 0.5,
			},
		},
		xaxis: {
			categories: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
			],
		},
	};
}
