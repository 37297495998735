




















































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Template } from "@/models/Template";
import {
	TEMPLATE_REQUEST,
	TEMPLATE_UPDATE,
	TEMPLATE_CREATE,
	TEMPLATE_DELETE,
	TEMPLATE_UPDATED,
	TEMPLATE_LOADED,
} from "@/store/actions/template";
import ChartPreview from "@/components/ChartPreview.vue";
import { EChartTypes } from "@/misc/Enums";
@Component({
	components: {
		ChartPreview,
	},
})
export default class Templates extends Vue {
	private templates: Template[] = [];
	private templatesHeaders = [
		{
			text: "Название",
			value: "template_name",
			align: "start",
			width: "35%",
		},
		{
			text: "Тип",
			value: "widget_type",
			align: "start",
			width: "35%",
		},
		{
			text: "Поля",
			value: "template_fields.length",
			align: "start",
			width: "10%",
		},
		{
			text: "Действия",
			value: "actions",
			align: "center",
			sortable: false,
			width: "10%",
		},
	];
	private loading: boolean = false;
	private previewDialog: boolean = false;
	private chartType: EChartTypes = EChartTypes.Diagram;
	private previewLabels = [];
	private previewData = [];
	private previewName = "";
	private search = '';
	private itemToDelete: Template = new Template();
  private deleteDialog: boolean = false;
	private deleteSnackbar: boolean = false;
  private deleteDisabled: boolean = false;
	//options
  private tablePage = 1;
  private tablePageCount = 0;
  private itemsPerPage = 15;

	//Initialise methods
	private isDeleting(id) {
    if (this.itemToDelete.id == id) return true;
    return false;
  }
	private cleanDeleting() {
    this.itemToDelete = new Template();
  }

	private initIfReady() {
		//return true if started to init self.
		let needReload = [] as boolean[];
		needReload.push(
			this.needReload(
				this.$store.getters.TEMPLATE_TABLE,
				this.$store.getters.TEMPLATE_LOADED
			)
		);

		for (let i = 0; i < needReload.length; i++) {
			if (needReload[i] == true) {
				return false;
			}
		}
		console.log("TRUE INIT SELF");
		this.initSelf();
		return true;
	}
	private needReload(table, updated) {
		/*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
		if (table == null || !updated) return true;
		return false;
	}
	private tryToInitSelf() {
		this.loading = true;
		//const data = this.getParams();
		if (this.initIfReady()) return;

		if (
			this.needReload(
				this.$store.getters.TEMPLATE_TABLE,
				this.$store.getters.TEMPLATE_LOADED
			)
		) {
			this.$store.dispatch(TEMPLATE_REQUEST).then(() => {
				this.initIfReady();
			});
		}
	}
	private initSelf() {
		console.log("INIT SELF");
		this.templates = this.$store.getters.TEMPLATE_TABLE.data;
		this.loading = false;
		console.log("loading");
	}
	//Methods
	private getType(type){
		return "" + EChartTypes[type];
	}
	private toCreate() {
		//localStorage.removeItem("templateToEdit");
		this.$router.push({ name: "TemplatesCreate" });
	}
	private toEdit(item) {
		console.log("item: ", item);
		const parsed = JSON.stringify(item);
		localStorage.setItem("templateToEdit", parsed);
		this.$router.push({ name: "TemplatesUpdate", params: { id: item.id } });
	}
	private deleteItem(item:Template, confirmation:boolean) {
		this.itemToDelete = item;
    if(confirmation)
    {
			this.deleteDialog = true;
		}
    else
		{
      console.log(item);
			console.log("Delete!");
			this.$store.dispatch(TEMPLATE_DELETE, item.id).then(() => {
				this.tryToInitSelf();
				this.deleteSnackbar = true;
				this.itemToDelete = new Template();
				this.deleteDisabled = false;
		});
		this.deleteDialog = false;
    }
	}
	private viewItem(template: any)
	{
		let labels = [] as any;
		let data = [] as any;
		template.data_table.values.forEach((item, key) =>
		{
			labels.push(item.key_name);
			data.push(item.data_value);
		});

		this.chartType = template.template_type;
		this.previewLabels = labels;
		this.previewData = data;
		this.previewName = template.data_table.table_name;
		this.previewDialog = true;
		console.log("chart type",this.chartType);
		console.log("lab",labels);
		console.log("pr",data);
		
	}
	//Hooks
	private mounted() {
		this.tryToInitSelf();
	}
	private nameSearch(value, search, item): boolean {
    return value != null &&
      search != null &&
      typeof value === 'string' &&
      value.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1
  }
}
