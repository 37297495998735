class Template {
  constructor(
    public id?: number,
    public template_name?: string,
    public widget_type?: number,
    public template_fields?: TemplateField[],
  ) {
    this.template_name = "";
    this.widget_type = 1;
    this.template_fields = [];
    this.template_name = template_name;
    this.widget_type = widget_type;
    this.template_fields = template_fields;
  }
}
class TemplateField {
  constructor(
    public key_name?: string,
    public key_type?: number,
  ) {
    this.key_name = key_name;
    this.key_type = key_type;
  }
}
export {
  Template, TemplateField
}